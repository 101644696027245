.update-wrapper {
  display: flex;
  align-items: center;
  max-width: 1288px;
  margin: auto;
  margin-bottom: 64px;
  width: 100%;
  // height: 100vh;
  height: 94vh;
  .update-img {
    margin-right: 60px;
  }
}
.update-modal-content {
  h1 {
    color: #0f314d;
    margin-bottom: 24px;
    margin-top: 0;
  }
  .update-tabs {
    display: flex;
    color: rgba(68, 68, 68, 0.3);

    cursor: pointer;
    border-bottom: 1px solid #d9d9d9;
    padding-bottom: 8px;
    margin-bottom: 24px;
    div {
      &:first-child {
        margin-right: 24px;
      }
    }
  }
  .geo-content {
    display: flex;
  }
  label {
    display: block;
    margin-bottom: 8px;

    color: #444444;
  }
  input {
    padding: 18px;
    background: #ffffff;
    border-radius: 4px;
    border: 1px solid transparent;
    width: 381px;
    font-weight: 400;
    font-size: 18px;
    &:hover {
      border: 1px solid #0f314d;
    }
    &:focus {
      border: 1px solid #0f314d;
    }
    &::placeholder {
      font-weight: 400;
      font-size: 16px;
      line-height: 1.5;
      color: rgba(68, 68, 68, 0.3);
    }
  }
  .modal-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    button {
      width: 112px;
      &:first-child {
        background-color: #ffffff;
        color: #0f314d;
        width: 97px;
        margin-right: 16px;
        filter: drop-shadow(0px 4px 4px #e8e8e8);
      }
      &:first-child:hover {
        filter: drop-shadow(0px 4px 4px #d6d6d6);
      }
    }
  }
}

.acitve-tab {
  color: #444444 !important;
}

.modal-title {
  font-weight: 700;
  font-size: 32px;
  line-height: 1.5;
}
.modal-subtitle {
  font-weight: 900;
  font-size: 20px;
  line-height: 1.5;
}
.field-name {
  font-weight: 700;
  font-size: 16px;
  line-height: 1.5;
}
.update-type {
  font-weight: 700;
  font-size: 20px;
  line-height: 1.5;
}
