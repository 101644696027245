.report-types-wrapper {
  max-width: 1288px;
  // width: 105%;
  margin: auto;
  margin-top: 32px;

  .report-types-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h1 {
      font-weight: 700;
      font-size: 32px;
      line-height: 48px;
      margin: 0;
    }
  }

  .report-types {
    margin-top: 40px;
    display: flex;
    gap: 16px;
    border-bottom: 1px solid #d9d9d9;

    div {
      padding: 8px 16px;
      cursor: pointer;
      font-weight: 500;
      font-size: 18px;
      line-height: 27px;
      color: #444444;
    }

    .active-tab {
      color: #0f314c;
      position: relative;
    }

    .active-tab::before {
      content: "";
      position: absolute;
      background-color: #0f314c;
      top: 100%;
      left: 0;
      width: 100%;
      height: 2px;
    }
  }
}

.empty-state-wrapper {
  margin-top: 110px;
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    margin: 16px 0;
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
    color: #0f314d;
  }

  span {
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    color: #444444;
  }
}

.reports-wrapper {
  margin-top: 24px;
  background: #ffffff;
  box-shadow: 0px 0px 4px #e8e8e8;
  border-radius: 8px;
  padding: 24px 32px;
  margin-bottom: 32px;
  overflow-x: auto;

  table {
    border-collapse: collapse;
    width: 100%;

    .icon-button {
      cursor: pointer;
      box-shadow: 0px 0px 9px rgba(169, 169, 169, 0.25);
      padding: 12px;
      border-radius: 100%;
    }

    .icon-button:hover {
      box-shadow: 0px 0px 9px rgba(106, 106, 106, 0.25);
    }

    thead {
      th {
        white-space: nowrap;
        text-align: start;
        padding-bottom: 24px;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        color: #444444;

        &:first-child {
          padding-left: 8px;
        }
      }
    }

    tbody {
      tr {
        border-top: 1px solid #d9d9d9;

        &:last-child {
          .custom-tooltip {
            top: auto;
            bottom: 36px;

            &::before {
              bottom: -9px;
              top: auto;
            }
          }
        }
      }

      td {
        padding-right: 35px;
        font-weight: 400;
        font-size: 18px;
        line-height: 27px;
        white-space: nowrap;

        &:nth-last-child(2) {
          max-width: 235px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        &:last-child {
          padding-right: 8px;
        }

        &:first-child {
          img {
            padding: 24px 0 24px 8px;
          }
        }

        .is-conversion {
          font-weight: 300;
          font-size: 12px;
          line-height: 18px;
          color: #444444;
        }
      }
    }
  }
}

.refresh-wrapper {
  position: relative;
}

.refresh-wrapper .refresh-tooltip {
  display: none;
  position: absolute;
  left: -270px;
  top: 50%;
  transform: translateY(-50%);
  background: #ffffff;
  border-radius: 8px;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #b3261e;
  padding: 12px;
  box-shadow: 0px 4px 20px rgba(95, 95, 95, 0.15);

  &::before {
    content: "";
    position: absolute;
    left: 100%;
    top: 13px;
    transform: translateY(-50%);
    transform: translate(-50%) rotate(45deg);
    width: 18px;
    height: 18px;
    background-color: #ffffff;
    border-radius: 1px;
  }
}

.delete-wrapper,
.refresh-tooltip {
  position: relative;
}

.delete-wrapper .delete-tooltip {
  display: none;
  position: absolute;
  left: -248px;
  top: 50%;
  transform: translateY(-50%);
  background: #ffffff;
  border-radius: 8px;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #444444;
  padding: 12px;
  box-shadow: 0px 4px 20px rgba(95, 95, 95, 0.15);

  &::before {
    content: "";
    position: absolute;
    left: 100%;
    top: 13px;
    transform: translateY(-50%);
    transform: translate(-50%) rotate(45deg);
    width: 18px;
    height: 18px;
    background-color: #ffffff;
    border-radius: 1px;
  }
}

.refresh-wrapper:hover .refresh-tooltip {
  display: block;
}

.delete-wrapper:hover .delete-tooltip {
  display: block;
}
