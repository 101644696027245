.container-main-auth {
  background-image: url("../../assets/icons/background.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100vh;
  position: relative;
  display: flex !important;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .auth-wrapper {
    position: relative;
    height: 100vh;
    height: 552px;
    width: 600px;
    .description {
      font-weight: 400;
      font-size: 16px;
      margin: 16px 0;
    }
    .auth-title {
      color: #444444;
      font-size: 24px;
      font-weight: 500;
      margin-top: 28px;
      margin-bottom: 8px;
    }
  }
  .forgot-pass-wrapper {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    .forgot-pass-text {
      margin-right: 6px;
    }
  }
}
