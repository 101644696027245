.expired-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  h2 {
    margin: 28px 0 16px 0;
    font-weight: 500;
    font-size: 24px;
    color: #444444;
  }
  p {
    margin: 0;
    font-weight: 400;
    font-size: 16px;
    color: #858585;
  }
}
.password-container {
  width: 100%;
  .sign-up-password-eye-icon {
    position: absolute;
    right: 6px;
    top: 44px;
  
  }

}
.pass {
  margin-top: 8px;

}
