.redirect-reset-password {
  margin-right: 32px;
  span {
    position: relative;
  }

  img {
    position: absolute;
    top: 1px;
    left: 8px;
  }
  a {
    font-weight: 400;
    font-size: 16px;
    color: #0f314d;
    text-decoration: none;
  }
  a:hover {
    text-decoration: underline;
  }
}
.title {
  font-weight: 500;
  font-size: 24px;
  color: #444444;
}

.reset-password-eye-icon {
  position: absolute;
}
.password {
  right: 70px;
  top: 260px;
}
.confirm-password {
  right: 6px;
  bottom: 27px;
}
.reset-password-button {
  margin-top: 32px;
}
