.success-error-wrapper {
  width: 100%;
  background-repeat: no-repeat;
}

.success-image {
  background-image: url("../../../../assets/icons/updateSuccess.svg");
  background-size: cover;
  background-position: center 45%;
}

.error-image {
  text-align: center;
  line-height: 0;
}
