.rdrStartEdge {
  color: #0f314c !important;
}
.rdrEndEdge {
  color: #0f314c !important;
}
.rdrInRange {
  color: #455a64 !important;
}
.rdrDateInput.rdrDateDisplayItem.rdrDateDisplayItemActive {
  border: 1px solid #0f314c !important;
}
.rdrDateDisplayWrapper {
  background-color: #f2f2f2 !important;
}
.rdrDateDisplay {
  margin-left: 0 !important;
  margin-right: 0 !important;
  margin-top: 0 !important;
}

.rdrDayToday .rdrDayNumber span:after {
  color: #0f314c !important;
}

.report-cancel-button {
  filter:  drop-shadow(0px 4px 4px #e8e8e8);
}
.report-cancel-button:hover {
  filter: drop-shadow(0px 4px 4px #d6d6d6);
}
