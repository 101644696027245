.airtable-update-preview {
  p {
    margin-bottom: 0px;
    border-bottom: 1px solid #d9d9d9;
  }

  span {
    padding: 8px;
  }
  .geos {
    max-height: 267px;
    overflow: auto;
    p {
      font-weight: 500;
      font-size: 18px;
      margin-top: 8px;
      margin-bottom: 0;
      border-top: 1px solid #d9d9d9;
      border-bottom: 0;
      padding: 8px;
      &:first-child {
        border: 0;
        margin: 0;
        padding-top: 0;
      }
    }
    span {
      padding: 0 8px;
      border: 0;
      margin-bottom: 8px;
      margin-top: 0;
      display: block;

    }
  }
}
