.basic-modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background: rgba(68, 68, 68, 0.5);
  width: calc(100%);
  height: 100vh;

  .modal-content {
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #eaeaea;
    padding: 32px 32px;
    box-shadow: 0px 2px 20px rgba(228, 228, 228, 0.25);
    border-radius: 8px;
    border-top: 13px solid #0f314d;
    overflow: hidden;
    .close {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      padding: 6px;
      border-radius: 50%;
      right: 10px;
      top: 10px;
      z-index: 9999;
      cursor: pointer;
      &:hover {
        background: rgba(0, 0, 0, 0.04);
      }
    }
  }
}
