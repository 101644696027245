.header-navbar-wrapper {
  margin: 0;
  position: relative;
  height: 100%;
}
.burger-item {
  img {
    margin-right: 26px;
    cursor: pointer;
  }
}
header {
  position: fixed;
  width: 100%;
  margin: 0;
  padding: 10px 26px;
  background: #fff;
  display: flex;
  align-items: center;
  z-index: 9;
  .logo-horizontal {
    cursor: pointer;
  }
}
aside {
  position: fixed;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 25px;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 50px);
  transition: 0.3s;
  background-color: #ffffff;
  z-index: 999;
  box-shadow: 0 20px 20px rgba(185, 185, 185, 0.25);
  ul {
    width: 100%;
    padding: 0;
    li {
      list-style: none;
    }
  }
}
.children-wrapper {
  padding-top: 56px;
  height: 100%;

  .container {
    max-width: 1440px;
    margin: auto;
    padding-bottom: 1px;
  }
}

.user-info {
  width: calc(100%);
  h4 {
    font-weight: 400;
    font-size: 16px;
    color: #444444;
    margin: 0 0 0 8px;
  }
  span {
    color: #b9b9b9;
    font-weight: 400;
    font-size: 11px;
    margin: 0 0 0 8px;
  }
}

.contents {
  white-space: nowrap;
  width: calc(100%);
}
#sidebar-wrapper {
  width: 72px;
}
#content-wrapper {
  width: calc(100% - 72px);
  margin: 0 auto;
}
