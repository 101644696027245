aside {
  white-space: nowrap;

  ul {
    text-align: center;
    margin-top: 32px;

    li {
      width: 100%;
      display: flex;
      align-items: center;

      span {
        margin-left: 8px;
        font-weight: 400;
        font-size: 18px;
      }
    }
  }

  .user-info {
    position: relative;

    .contents {
      overflow: hidden;
      transition: 0.3s;
      display: flex;
      justify-content: center;

      .user-info-container {
        width: calc(100% - 16px);

        .line {
          width: 100%;
          height: 1px;
          background-color: #d9d9d9;
          margin-top: 16px;
        }
      }
    }

    .logout {
      cursor: pointer;
      display: flex;
      align-items: center;
      position: relative;
      .sidebar-tooltip {
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        color: #444444;
      }
      &:hover {
        background-color: #f9f9f9;
      }

      span {
        margin-left: 8px;
        font-weight: 400;
        font-size: 18px;
        white-space: nowrap;
        color: #b9b9b9;
      }
    }
  }
}

.sidebar-item_dis {
  user-select: none;
  pointer-events: none;
}

.sidebar-item {
  position: relative;
  .sidebar-tooltip {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #444444;
  }
  &:hover {
    background-color: #f9f9f9;
  }
}

.sidebar-tooltip {
  display: none;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  position: absolute;
  left: 65px;
  top: 50%;
  transform: translateY(-50%);
  background: #ffffff;
  border-radius: 8px;
  color: #444444;
  padding: 8px 16px;
  box-shadow: 0px 4px 20px rgba(95, 95, 95, 0.15);
  z-index: 9999;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: calc(50% - 8px);
    transform: translate(-50%) rotate(45deg);
    width: 12px;
    height: 12px;
    background-color: #ffffff;
    border-radius: 1px;
  }
}

.sidebar-item:hover .sidebar-tooltip {
  display: block;
}
.logout:hover .sidebar-tooltip {
  display: block;
}
