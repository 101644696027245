.searched-campaigns-container {
  margin-top: 240px;
  .no-duplicated-campaigns {
    text-align: center;
    h2 {
      font-weight: 700;
      font-size: 18px;
      color: #0f314d;
      margin-top: 16px;
    }
    p {
      font-weight: 400;
      font-size: 18px;
      color: #444444;
      margin: 16px;
    }
  }
}
.spinner-wrapper {
  text-align: center;
  margin-top: 46px;
  .loading-campaign {
    animation: 0.8s linear infinite spinner;
    -webkit-animation: 0.8s linear infinite spinner;
    animation-play-state: inherit;
    transform: translate3d(-50%, -50%, 0);
    will-change: transform;
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes fadeInOut {
  0% { opacity: 0; transform: translateX(-10px); }
  100% { opacity: 1; transform: translateX(0); }
}
