.single-campaign-wrapper {
  background: #ffffff;
  box-shadow: 0px 0px 4px #e8e8e8;
  border-radius: 4px;
  overflow: hidden;
  // padding: 0 32px 0 23px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 2px 16px;

  fieldset {
    border-color: transparent !important;
  }

  .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root:hover {
    background-color: red !important;
  }

  &:last-child {
    margin-bottom: 0;
  }

  .campaign-details {
    display: flex;
    align-items: center;

    // margin-left: 32px;
    .disabled-campaigns-details {
      width: 24px;
      height: 24px;
      border-radius: 4px;
      border: 1px solid #e1e1e1;
    }

    .campaign-info {
      margin-left: 32px;

      h3 {
        font-weight: 400;
        font-size: 18px;
        color: #444444;
        display: inline-block;
        margin: 4px 12px 8px 0;
      }

      .campaign-id {
        font-weight: 400;
        font-size: 16px;
        color: #959595;
        margin-bottom: 10px;
      }

      .campaign-status {
        border-radius: 15px;
        padding: 4px 8px;
        margin-top: 8px;
      }

      span {
        font-weight: 400;
        font-size: 14px;
        color: #959595;

        &:last-child {
          font-weight: 400;
        }
      }

      .disabled-badge {
        background: #e1e1e1;
        color: #959595;
      }
    }

    .disabled {
      h3 {
        color: #959595;
        font-weight: 400;
        font-size: 18px;
      }
    }
  }

  .tooltip-budget {
    position: relative;
    background: #ffffff;
    box-shadow: 0px 0px 4px #e8e8e8;
    border-radius: 8px;
    padding: 8px 16px;
    color: #444444;
    font-weight: 500;
    font-size: 12px;
    margin-right: 16px;
    display: flex;
    align-items: center;
    background-color: #fff;
    z-index: 999;

    .tooltip-arrow {
      margin-right: 0;
    }

    span {
      display: flex;
      align-items: center;
      margin: 0 0;
      color: #959599;
      line-height: 1.5;
      font-weight: 400;
      font-size: 12px;
    }

    img {
      margin-right: 8px;
    }

    div {
      position: absolute;
      top: 50%;
      left: -8px;
      transform: translateY(-50%);
    }
  }

  .error-tooltip {
    box-shadow: 0px 4px 20px rgba(95, 95, 95, 0.15);
  }

  .budget-allocation-error {
    color: #444444;
    font-weight: 400;
  }

  .budget-allocation {
    display: flex;
    align-items: center;

    .budget-allocation-title {
      margin-right: 129px;
      color: #444444;
      font-weight: 400;
    }

    .budget-allocation-percentage {
      border-radius: 4px;
      margin-right: 8px;
      color: #444444;
      display: flex;
      align-items: center;
      border: 1px solid #d9d9d9;

      input {
        color: #444444;
      }

      &:has(input:focus) {
        border: 1px solid #0f314d;
      }

      span {
        position: relative;
        padding: 6px 8px;

        &::before {
          content: "";
          position: absolute;
          display: block;
          width: 1px;
          height: 24px;
          top: 4px;
          left: 0;
          background-color: #d9d9d9;
        }
      }
    }

    span {
      font-weight: 300;
      font-size: 18px;
      color: #939393;
    }

    input {
      border-radius: 4px;
      padding: 8px 16px;
      width: 55px;
      border: none;
      text-align: center;

      &::placeholder {
        color: "#B7B7B7";
      }

      &:focus::placeholder {
        color: transparent;
      }
    }
  }

  .active-campaign {
    margin-left: 32px;
  }
}

.disabled-campaign {
  // background: #eaeaea;
  position: relative;
  background: #eaeaea;
  box-shadow: 0px 0px 4px #e8e8e8;
  border-radius: 4px;
  padding: 12px 32px;
  border-left: 6px solid #959595;
  max-width: 1070px;
}

.custom-select {
  position: relative;
  font-family: Arial, sans-serif;
}

.custom-select select {
  display: none;
  /*hide original SELECT element:*/
}

.select-selected {
  background-color: DodgerBlue;
}

/*style the arrow inside the select element:*/
.select-selected:after {
  position: absolute;
  content: "";
  top: 14px;
  right: 10px;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-color: #fff transparent transparent transparent;
}

/*point the arrow upwards when the select box is open (active):*/
.select-selected.select-arrow-active:after {
  border-color: transparent transparent #fff transparent;
  top: 7px;
}

/*style the items (options), including the selected item:*/
.select-items div,
.select-selected {
  color: #ffffff;
  padding: 8px 16px;
  border: 1px solid transparent;
  border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
  cursor: pointer;
  user-select: none;
}

/*style items (options):*/
.select-items {
  position: absolute;
  background-color: DodgerBlue;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
}

/*hide the items when the select box is closed:*/
.select-hide {
  display: none;
}

.select-items div:hover,
.same-as-selected {
  background-color: rgba(0, 0, 0, 0.1);
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 8px 16px !important;
}

.css-1nwlj3f-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border-color: transparent !important;
  border-width: 0 !important;
}

.css-1elwnq4-MuiPaper-root-MuiAccordion-root:before {
  content: unset !important;
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root.Mui-selected {
  background-color: rgba(15, 49, 77, 0.05);
  border-radius: 4px !important;
  padding: 8px 10px;
}

.option-value-wrapper {
  font-weight: 400;
  font-size: 16px;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    position: absolute;
  }

  &::-webkit-scrollbar-thumb {
    -webkit-border-radius: 5px;
    border-radius: 5px;
    height: 2px;
    background: #d8d8d8;
  }
}

.MuiButtonBase-root.MuiMenuItem-root:hover {
  background-color: #f3f4f6 !important;
  border-radius: 4px;
}

.css-1elwnq4-MuiPaper-root-MuiAccordion-root.Mui-disabled {
  background-color: #eaeaea !important;
  color: #959595 !important;
}

.css-qiwgdb.css-qiwgdb.css-qiwgdb {
  padding: 8px 16px !important;
}

.check-campaign {
  position: absolute;
  top: 16px;
  z-index: 9;
  left: 32px;
}

.form-id {
  display: flex;
  align-items: center;
  padding-left: 68px;
  color: #444444;
  font-size: 18px;
}

.budget-allocation-wrapper {
  display: flex;
  align-items: center;
  padding-left: 68px;
  position: relative;
  justify-content: space-between;

  span {
    // right: 32px;
    // bottom: 16px;
    color: #959595;
    font-weight: 300;
    font-size: 16px;
  }
}

.Sustainable {
  color: #0f314d;
  background: rgba(15, 49, 77, 0.15);
}

.PreSale {
  background: rgba(51, 133, 13, 0.15);
  color: #33850d;
}

.SoftOpening {
  background: rgba(246, 156, 44, 0.15);
  color: #f69c2c;
}

.Onboarding {
  color: #9a004a;
  background: rgba(154, 0, 74, 0.15);
}
