.brands-wrapper {
  max-width: 1288px !important;
  margin: auto;
  margin-top: 32px;

  .brands-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    h1 {
      font-weight: 700;
      font-size: 32px;
      line-height: 48px;
      margin: 0;
    }
    .request-report:hover {
      background: #3f5a71;
    }
  }
  .brands-container {
    margin-top: 33px;
    display: flex;
    gap: 24px;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 40px;
    .brands {
      width: calc((100% - 48px) / 3);
      cursor: pointer;
      display: flex;
      height: 141px;
      align-items: center;
      justify-content: center;
      background: #ffffff;
      box-shadow: 0px 0px 4px #e8e8e8;
      border-radius: 8px;
    }
    .brands:hover {
      box-shadow: 0px 0px 9px rgba(106, 106, 106, 0.25);
    }
  }
}
