html {
  box-sizing: border-box;
  height: 100%;
  overflow: hidden;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}


body,
button {
  margin: 0;
  background-color: #f9f9f9;
  font-family: "Roboto", Arial, sans-serif;
  height: 100%;
}

#root {
  height: 100%;
}

.root_overFlow {
  overflow: auto;
}

.app {
  height: 100%;
}

.title {
  font-weight: 500;
  font-size: 24px;
  color: #444444;
  margin-bottom: 16px;
  margin-top: 32px;
}

input {
  outline: none;

  &:focus-visible {
    outline: none;
  }
}

::-webkit-scrollbar {
  width: 12px;
  position: absolute;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 5px;
  border-radius: 5px;
  background: #d8d8d8;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.header-title {
  font-weight: 700;
  font-size: 32px;
  color: #0f314d;
  text-align: center;
}

.header-description {
  font-weight: 400;
  font-size: 24px;
  color: #444444;
  text-align: center;
}

.select-input-wrapper,
.single-location-modal-content,
.form-id-item {
  fieldset {
    border-color: transparent !important;
    border-width: 0 !important;
  }
}

.css-1apw10x-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border-color: transparent !important;
}

.css-7dtazw.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: transparent !important;
}

.ckeditor-container {
  /* Change to your desired color */
  transition: border-color 0.3s;
}

/* Border color when focused */
.ck.ck-editor__main > .ck-editor__editable:not(.ck-focused) {
  // border-color: transparent;
  border-color: #d9d9d9 !important;
  border-radius: 0 0 8px 8px !important;
}

.ck-sticky-panel__content > .ck-toolbar {
  border-radius: 8px 8px 0 0 !important;
}

.ck-focused {
  border-color: #d9d9d9 !important;
  box-shadow: none !important;
  border-radius: 0 !important;
}

.ck-button.ck-disabled {
  color: #cbcbcb !important;
}

.afd {
  fieldset {
    border-color: transparent !important;
    border-width: 0 !important;
  }
}
