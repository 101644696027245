.invalid-user {
  color: #e93131;
  font-size: 12px;
  font-weight: 500;
  margin-top: 0;
}

.forgot-pass {
  text-align: right;
  margin-bottom: 32px;
  display: flex;

  a {
    color: #858585;
    text-decoration: none;
    font-weight: 400;
    font-size: 12px;
  }
}
.password-container {
  position: relative;
  .eye-icon {
    display: flex;
    align-items: center;
    background-color: #fff;
    width: 58px;
    height: 40px;
    position: absolute;
    right: 2px;
    bottom: 8px;
  }
}

.sign-in {
  margin-top: 32px;
}
