.all-duplications-data {
  box-shadow: 0 0 4px #e8e8e8;
  margin: 0 2px 24px 2px;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  .copy-id {
    position: absolute;
    top: 17px;
    left: 390px;
    z-index: 9;
    color: #fff;
    cursor: pointer;
    &:hover {
      svg {
        path {
          fill: #d8d8d8;
        }
      }
    }
    svg {
      width: 16px;
    }
  }
  .campaign-name {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #f4f4f4;
    span {
      margin: 16px 78px 16px 32px;
      font-weight: 400;
      font-size: 18px;
    }
    a {
      color: #034a95;
      font-weight: 400;
      font-size: 18px;
    }
    .link-in-progress {
      display: flex;
      margin-right: 36px;
      p {
        margin: 0;
        font-weight: 400;
        font-size: 18px;
        color: #2e2e2e;
      }
      img {
        margin-left: 8px;
      }
    }
    .no-link {
      display: block;
      color: #000;
      width: 36px;
      text-align: center;
    }
  }
  .campaign-info {
    margin: 16px 15px;
    color: #ffffff;
    .campaign-id {
      font-weight: 400;
      font-size: 18px;
    }
    .created-on {
      margin-top: 8px;
      font-weight: 300;
      font-size: 14px;
    }
  }

  .account-name {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #f4f4f4;
    span {
      margin: 16px 32px;
      font-weight: 500;
      font-size: 18px;
      color: #2e2e2e;
    }
  }
  .tooltip-budget {
    position: absolute;
    z-index: 999;
    left: 424px;
    background: #fff;
    top: 11px;
    display: flex;
    padding: 8px 16px 8px 10px;
    box-shadow: 0 4px 20px rgba(95, 95, 95, 0.15);
    border-radius: 8px;
    .pointer-left {
      position: relative;
      left: -17px;
      top: 2px;
    }
  }
}
