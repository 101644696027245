.airtable-update-preview {
  h1 {
    margin-bottom: 0;
  }
  p {
    font-size: 20px;
    color: #444444;
    margin-top: 16px;
    margin-bottom: 8px;
    border-bottom: 1px solid #d9d9d9;
    padding: 8px;
  }
  span {
    font-size: 18px;
    color: #444444;
    margin-top: 8px;
  }
  .lead-forms {
    height: 163px;
    overflow-y: scroll;
    p {
      font-weight: 400;
      font-size: 18px;
      line-height: 1.5;
      color: #444444;
      border-bottom: 1px solid #d9d9d9;
      padding: 8px;
      margin: 0;
      &:last-child {
        border: 0;
      }
      &:first-child {
        padding-top: 0;
      }
    }
  }
}
