.error-message {
  display: flex;
  align-items: center;
  margin: 0 0;
  color: #b3261e;
  font-weight: 400;
  font-size: 11px;
  img {
    margin-right: 6px;
  }
}
