.geos-name {
  width: 780px;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
}

.tooltip-container {
  position: relative;
  cursor: pointer;
}
.tooltip-text {
  position: absolute;
  left: 58%;
  top: 90%;
  width: 645px;
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(95, 95, 95, 0.15);
  border-radius: 8px;
  word-wrap: break-word;
  transform: translateX(-50%);
  visibility: hidden;
  opacity: 0;
  z-index: 999;
  padding: 16px !important;
  font-weight: 400;
  font-size: 18px;
  &::before {
    content: "";
    position: absolute;
    left: 90%;
    top: -9px;
    transform: translateX(-50%) rotate(45deg);
    width: 18px;
    height: 18px;
    background-color: #ffffff;
    border-radius: 1px;
  }
}
.tooltip-container:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}
.custom-tooltip-text {
  white-space: nowrap;
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
}
.custom-tooltip-container {
  position: relative;
  .custom-tooltip-text {
    white-space: nowrap;
    overflow: hidden;
    display: block;
    text-overflow: ellipsis;
  }

  .custom-tooltip {
    position: absolute;
    left: 58%;
    top: 126%;
    max-width: 400px;
    background: #ffffff;
    box-shadow: 0 4px 20px rgba(95, 95, 95, 0.15);
    border-radius: 8px;
    word-wrap: break-word;
    transform: translateX(-50%);
    visibility: hidden;
    opacity: 0;
    z-index: 999;
    padding: 16px !important;
    font-weight: 400;
    font-size: 18px;

    &::before {
      content: "";
      position: absolute;
      left: 75%;
      top: -9px;
      transform: translateX(-50%) rotate(45deg);
      width: 18px;
      height: 18px;
      background-color: #ffffff;
      border-radius: 1px;
    }
  
  }
}
.custom-tooltip-container:hover .custom-tooltip {
  visibility: visible;
  opacity: 1;
  white-space: pre-wrap;
}
