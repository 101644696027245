.description {
  font-size: 18px;
  font-weight: 300;
  color: #4d4d4d;
  text-align: center;
  margin: 16px 64px;
}

.auth-button {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #0f314d;
  border-radius: 4px;
  font-weight: 400;
  font-size: 16px;
  color: #fff;
  width: 100%;
  padding: 16px 24px;
  height: 56px;
  margin-top: 24px;
  text-decoration: none;
  cursor: pointer;
  border: none;
  &:hover {
    background: #3f5a71;
  }
  &:disabled {
    background: #657b8d;
    cursor: initial;
  }
}

.MuiFormHelperText-root.Mui-error {
  margin-left: 8px;
  font-size: 11px;
}
